section.login{
    form{
        border-radius: 1.5rem;
    }
    button{
        background-color:#4c7d78;
    }
    .user-icon, .pass-icon{
        right: 15px;
        top: 35px;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: #4c7d78;
            }
        }
    }
}