@import "src/App.scss";

section.stage{
    form{
        border-radius: 1.5rem;
    }
    button{
        background-color:#4c7d78;
    }
    .user-icon, .pass-icon{
        right: 15px;
        top: 40px;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: #4c7d78;
            }
        }
    }
    .form-control:focus {
        color: $secondary-color !important;
        border-color: $secondary-color !important;
        box-shadow: 0 0 0 0.25rem rgba(76,125,120,.25) !important;
    }
    .inputErr.form-control:focus {
        color: red !important;
        border-color: red !important;
        box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25) !important;
    }
    ::placeholder {
        color: $secondary-color;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }


    .ql-editor {
        direction: rtl;
        text-align: right;
        padding-left: 1.3rem;
        padding-right: 1.3rem;
      }
      .ql-editor.ql-blank::before {
        left: 1.5rem !important;
        right: 1.5rem !important;
    }
    .inputErr .ql-container.ql-snow {
        color: red !important;
        border: 1px solid red !important;
        box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25) !important;
    }
    .ql-snow .ql-picker-label {
        padding-right: 20px !important;
    }
    
    

    .bgcolor{
        background-color: $tertiary-bg;
    }
    
}
