@import "src/App.scss";

section.nav-bar{
   height: 100vh;
   ul.nav{
      padding-right: 0 !important;
      height: 90vh;
   }
   .nav-link{
      color: $primary-color;
   }
   svg{
      width: 20px;
      height: 20px;
   }
}
